<template>
    <div class="bg-gray-300 w-96 border-solid border-2 border-black  rounded-md">
        <div class="flex justify-center">
            <span class="font-bold"> {{ props.produtoSKU }}</span>
        </div>
        <div class="flex justify-center">
            <span class=" text-center"> {{ props.produtoDetalhes }}</span>
        </div>
        <div class="flex justify-center">
            <span>Quantidade : {{ props.produtoQuantidade }}</span>
        </div>
        <div class="flex justify-center">
            <span> {{ toMoney(props.produtoValor) }}</span>
        </div>

    </div>
</template>

<script>
import { toDate, toMoney, numberToCpf, numberToCnpj } from "../../services/formater";
export default {
    name: "ProdutoNome",
    props: {
        produtoDetalhes: String,
        produtoSKU: String,
        produtoValor: String,
        produtoQuantidade: String,
    },

    setup(props) {
        return {
            props,
            toMoney
        }
    }
}
</script>

<style></style>